import React from "react"

import Container from "../../Layout/Container"
import DashboardLayout from "../../Layout/DashboardLayout"
import PatientForm from "../PatientFormPage/PatientForm"

const PatientAdd = ({ pageContext, location }) => {
  let { module, formFields } = pageContext

  return (
    <DashboardLayout seoTitle={module.title} pageContext={pageContext}>
      <Container isCentered tablet={8} desktop={10} fullhd={10}>
        <h2 className="has-text-primary my-3">{module.title}</h2>
        <PatientForm formFields={formFields} module={module} />
      </Container>
    </DashboardLayout>
  )
}

export default PatientAdd
